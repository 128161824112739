function getCookie(key) {
  return (
    document.cookie
      .split(';')
      .map(cookie => cookie.trim())
      .find(cookie => new RegExp(`^${key}=.*`).test(cookie)) || ''
  ).substr(key.length + 1);
}

function setCookie(
  key,
  value,
  { path = '/', domain = window.location.hostname, maxAge, expires, secure, sameSite } = {},
) {
  if (key && (value || value === '')) {
    document.cookie = `${key}=${value};path=${path};domain=${domain};${
      maxAge ? `max-age=${maxAge};` : ''
    }${expires ? `expires=${expires};` : ''}${secure ? 'secure;' : ''}${
      sameSite ? `samesite=${sameSite};` : ''
    }`;
  }
}

function getPossibleDomains() {
  const parts = window.location.hostname.split('.');
  const domains = [];
  for (let i = 2; i <= parts.length; i++) {
    domains.push(parts.slice(-i).join('.'));
  }
  return domains;
}

function clearCookie(key) {
  if (getCookie(key)) {
    getPossibleDomains().forEach(domain => setCookie(key, '', { domain, maxAge: '-99999999' }));
  }
}

function getState() {
  return JSON.parse(window.localStorage.getItem('channelsState')) || {};
}

function setState(stateObj) {
  window.localStorage.setItem('channelsState', JSON.stringify({ ...getState(), ...stateObj }));
}

function clearStorage() {
  const genesysCookieNames = document.cookie
    .split(';')
    .map(cookie => cookie.trim().split('=')[0])
    .filter(name => name.startsWith('_genesys'));
  genesysCookieNames.forEach(name => clearCookie(name));
  window.localStorage.removeItem('channelsState');
}

const chatSessionCookieKey = '_genesys.widgets.webchat.state.session';

export {
  getCookie,
  setCookie,
  clearCookie,
  chatSessionCookieKey,
  setState,
  getState,
  clearStorage,
};
