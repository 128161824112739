const Localizations = {
  en: {
    webchat: {
      ChatButton: 'Chat',
      ChatStarted: '',
      ChatEnded: 'Your chat session has been closed.',
      AgentNameDefault: 'Agent',
      AgentConnected: '<%Agent%> connected',
      BotConnected:
        'Welcome to chat, please do not share sensitive information such as social security or credit card numbers.',
      AgentDisconnected: '<%Agent%> disconnected',
      BotDisconnected: 'Please wait while we connect you to a Travelers representative.',
      SupervisorNameDefault: 'Supervisor',
      SupervisorConnected: '<%Agent%> connected',
      SupervisorDisconnected: '<%Agent%> disconnected',
      AgentTyping: '<%Agent%> is typing...',
      AgentUnavailable: 'Sorry. There are no agents available. Please try later',
      ChatTitle: 'Chat',
      ChatEnd: 'X',
      ChatClose: 'X',
      ChatMinimize: 'Min',
      ChatFormFirstName: 'First Name',
      ChatFormLastName: 'Last Name',
      ChatFormNickname: 'Nickname',
      ChatFormEmail: 'Email',
      ChatFormSubject: 'Subject',
      ChatFormPlaceholderFirstName: 'Required',
      ChatFormPlaceholderLastName: 'Required',
      ChatFormPlaceholderNickname: 'Optional',
      ChatFormPlaceholderEmail: 'Required',
      ChatFormPlaceholderSubject: 'Required',
      ChatFormSubmit: 'Start Chat',
      ChatFormCancel: 'Cancel',
      ChatFormClose: 'Close',
      ChatInputPlaceholder: 'Type your message here',
      ChatInputSend: 'SEND',
      ChatEndQuestion: 'Are you sure you want to end this chat session?',
      ChatEndCancel: 'Cancel',
      ChatEndConfirm: 'End chat',
      ConfirmCloseWindow: 'Are you sure you want to close chat?',
      ConfirmCloseCancel: 'Cancel',
      ConfirmCloseConfirm: 'Close',
      ActionsDownload: 'Download transcript',
      ActionsEmail: 'Email transcript',
      ActionsPrint: 'Print transcript',
      ActionsCobrowseStart: 'Start Co-browse',
      ActionsSendFile: 'Send File',
      ActionsCobrowseStop: 'Exit Co-browse',
      ActionsVideo: 'Invite to Video Chat',
      ActionsTransfer: 'Transfer',
      ActionsInvite: 'Invite',
      InstructionsTransfer:
        'Open this link on another device to transfer your chat session</br></br><%link%>',
      InstructionsInvite:
        'ShowSurveyAcceptare this link with another person to add them to this chat session</br></br><%link%>',
      InviteTitle: 'Need help?',
      InviteBody: 'Let us know if we can help out.',
      InviteReject: 'No thanks',
      InviteAccept: 'Start chat',
      ChatError: 'There was a problem starting the chat session. Please Retry.',
      ChatErrorButton: 'OK',
      DownloadButton: 'Download',
      FileSent: 'has sent:',
      FileTransferRetry: 'Retry',
      FileTransferError: 'OK',
      FileTransferCancel: 'Cancel',
      RestoreTimeoutTitle: 'Chat ended',
      RestoreTimeoutBody:
        'Your previous chat session has timed out. Would you like to start a new one?',
      RestoreTimeoutReject: 'No thanks',
      RestoreTimeoutAccept: 'Start chat',
      EndConfirmBody: 'Would you really like to end your chat session?',
      EndConfirmAccept: 'End chat',
      EndConfirmReject: 'Cancel',
      SurveyOfferQuestion: 'Would you like to participate in a survey?',
      ShowSurveyAccept: 'Yes',
      ShowSurveyReject: 'No',
      UnreadMessagesTitle: 'unread',
      AriaYouSaid: 'You said',
      AriaSaid: 'said',
      AriaSystemSaid: 'System said',
      AriaMinimize: 'Live Chat minimize',
      AriaMaximize: 'Live Chat Maximize',
      AriaClose: 'Live chat close',
      Errors: {
        '102': 'First name is required',
        '103': 'Last name is required',
        '161': 'Please enter your name',
        '201':
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='advice'>The maximum number of attached files would be exceeded (<%MaxFilesAllowed%>)</p>",
        '202':
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='advice'>Upload limit and/or maximum number of attachments would be exceeded (<%MaxAttachmentsSize%>)</p>",
        '203':
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='advice'>File type is not allowed.</p>",
        '204': "We're sorry but your message is too long. Please write a shorter message",
        '240': "We're sorry but we cannot start a new chat at this time. Please try again later",
        '364': 'Invalid email address',
        ChatUnavailable:
          "We're sorry but we cannot start a new chat at this time. Please try again later",
        CriticalFault:
          'Your chat session has ended unexpectedly due to an unknown issue. We apologize for the inconvenience',
        StartFailed:
          'There was an issue starting your chat session. Please verify your connection and that you submitted all required information properly, then try again',
        MessageFailed: 'Your message was not received successfully. Please try again',
        RestoreFailed:
          "We're sorry but we were unable to restore your chat session due to an unknown error",
        TransferFailed: 'Unable to transfer chat at this time. Please try again later',
        FileTransferSizeError:
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='advice'>File size is larger than the allowed size (<%MaxSizePerFile%>)</p>",
        InviteFailed: 'Unable to generate invite at this time. Please try again later',
        ChatServerWentOffline:
          "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        RestoredOffline:
          "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        Disconnected: "<div style='text-align:center'>Connection lost</div>",
        Reconnected: "<div style='text-align:center'>Connection restored</div>",
        FileSendFailed:
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='advice'>There was an unexpected disconnection. Try again?</p>",
        Generic: "<div style='text-align:center'>An unexpected error occurred</div>",
      },
    },
    cobrowse: {
      toolbarContent: 'Session ID:{sessionId}<br/> A representative is co-browsing with you.',
      agentJoined: 'A representative is now co-browsing with you.',
      agentLeft: 'Representative has left the session. Co-browse has now ended.',
      youLeft: 'You have left the session. Co-browse has now ended.',
      sessionTimedOut: 'Session timed out. Co-browse has now ended.',
      sessionInactiveTimedOut: 'Session timed out. Co-browse has now ended.',
      sessionError: 'Unexpected error occurred. Co-browse has now ended.',
      sessionsOverLimit:
        'Representative is currently busy with another Co-browse session. Co-browse has now ended.',
      serverUnavailable: 'Could not reach Co-browse server. Co-browse has now ended.',
      sessionStarted:
        'Your Co-browse session ID is {sessionId}. Please share it with our representative to begin co-browsing.',
      connectBeforeCobrowse:
        'You need to be connected with our representative to continue with co-browsing. Please contact us.',
    },
  },
};

export default Localizations;
