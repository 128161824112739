import 'custom-event-polyfill';
import 'core-js/features/symbol';
import elementClosest from 'element-closest';
import '@trv-ebus/tds-icons/dist/css/tds-icon-sizes.css';
import '../css/channels.less';
import 'whatwg-fetch';

elementClosest(window);

export * from './channels';
