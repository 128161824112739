function showEndChatButton() {
  const buttonGroup = document.querySelector('.cx-button-group.cx-buttons-window-control');
  buttonGroup.hidden = false;
  const minimizeButton = document.querySelector('.cx-icon.cx-button-minimize');
  minimizeButton.style.display = 'none';
  const closeButton = document.querySelector('.cx-icon.cx-button-close');
  closeButton.classList.remove('cx-icon');
  closeButton.classList.remove('cx-button-close');
  closeButton.classList.add('cx-end-chat-button');
  buttonGroup.classList.add('cx-buttongroup-window');
  const titlebar = document.querySelector('.cx-titlebar');

  titlebar.classList.add('cx-custom-titlebar');

  closeButton.textContent = 'End Chat';
  closeButton.addEventListener('click', () => {
    const endChatSessionButton = document.querySelector(
      '.cx-end-confirm.cx-btn.cx-btn-primary.i18n',
    );
    endChatSessionButton.id = 'endChatConfirmButton';
  });
}

export default showEndChatButton;
