// The code in this file is based on the Genesys Altocloud tracking snippet: https://all.docs.genesys.com/ATC/Current/AdminGuide/Tracking_snippet
const setup = () => {
  window._genesysJourneySdk = 'ac';
  const queuePush = (...args) => {
    (window.ac.q = window.ac.q || []).push(args);
  };
  window.ac = window.ac || queuePush;
  window.ac.l = Date.now();
  const script = document.createElement('script');
  const [firstScript] = document.getElementsByTagName('script');
  script.async = true;
  script.src = 'https://apps.mypurecloud.com/journey/sdk/js/web/v1/ac.js';
  script.charset = 'utf-8';
  firstScript.parentNode.insertBefore(script, firstScript);
};

const track = () => {
  setup();
  window.ac('init', 'c7048cef-4fea-43d4-8017-cbb21760f136', { region: 'use1' });
  window.ac('pageview');
};

export default track;
