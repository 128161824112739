const LOG_LEVELS = {
  OFF: 0,
  ERROR: 100,
  WARN: 200,
  INFO: 300,
  TRACE: 400,
  ALL: 100000,
};

let LEVEL_NUMBER;
switch ('OFF') {
  case 'OFF':
  default:
    LEVEL_NUMBER = 0;
    break;
  case 'ERROR':
    LEVEL_NUMBER = 100;
    break;
  case 'WARN':
    LEVEL_NUMBER = 200;
    break;
  case 'INFO':
    LEVEL_NUMBER = 300;
    break;
  case 'TRACE':
    LEVEL_NUMBER = 400;
    break;
  case 'ALL':
    LEVEL_NUMBER = 100000;
    break;
}

function out(type, file, funct, message) {
  const msg = `CHAT ~ ${type} | ${file} - ${funct} | ${message}`;

  if (LOG_LEVELS[type] >= LOG_LEVELS.INFO) {
    console.log(msg);
  } else if (LOG_LEVELS[type] === LOG_LEVELS.WARN) {
    console.warn(msg);
  } else if (LOG_LEVELS[type] === LOG_LEVELS.ERROR) {
    console.error(msg);
  }
}

function isEnabled(incomingLevel) {
  if (LEVEL_NUMBER && LOG_LEVELS[incomingLevel] <= LEVEL_NUMBER) {
    return true;
  }

  return false;
}

function trace(file, funct, message) {
  if (isEnabled('TRACE')) {
    out('TRACE', file, funct, message);
  }
}

function info(file, funct, message) {
  if (isEnabled('INFO')) {
    out('INFO', file, funct, message);
  }
}

function warn(file, funct, message) {
  if (isEnabled('WARN')) {
    out('WARN', file, funct, message);
  }
}

function error(file, funct, message) {
  if (isEnabled('ERROR')) {
    out('ERROR', file, funct, message);
  }
}

function isDebugEnabled() {
  if (LEVEL_NUMBER === LOG_LEVELS.ALL) {
    return true;
  }

  return false;
}

export default { trace, info, warn, error, isDebugEnabled };
