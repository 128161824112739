import { getCookie, chatSessionCookieKey, setState, getState } from './storageManager';
import Notification from './notification';
import injectTranscriptButton from './transcript';
import showEndChatButton from './survey';

let isWindowActive = true;
let isUserConnected = false;
let isBotConnected = false;
let channelConnected = [];
let hiddenButtons = [];
/**
 * TODO: I'm sorry; Hopefully a Genesys fix will resolve need for timeout here
 */
function toggleUserTyping(shouldEnable, timeout) {
  if (shouldEnable) {
    // Enable client ability to type in Chat
    setTimeout(() => {
      const msgInputTextArea = document.getElementsByClassName('cx-message-input')[0];
      if (msgInputTextArea) {
        msgInputTextArea.disabled = false;
        msgInputTextArea.placeholder = 'Type your message here';
      }
    }, timeout);
  } else {
    // Disable client ability to type in chat
    setTimeout(() => {
      const msgInputTextArea = document.getElementsByClassName('cx-message-input')[0];
      if (msgInputTextArea) {
        msgInputTextArea.disabled = true;
        msgInputTextArea.placeholder = '';
      }
    }, timeout);
  }
}

function registerChatChannelEvents(userId) {
  window.onfocus = () => {
    isWindowActive = true;
  };
  window.onblur = () => {
    isWindowActive = false;
  };
  const CXBus = window._genesys.widgets.bus;
  let isChatRestoring = false;

  function onConnected(details) {
    isBotConnected = details && details.data.message.from.type === 'Bot';
    channelConnected.push(details.data.message.from.type);

    isUserConnected = true;
    if (isBotConnected && !isChatRestoring) {
      toggleUserTyping(false, 0);
    } else {
      toggleUserTyping(true, 1000);
    }
    if (
      !isChatRestoring &&
      window.channelsConfig.chat &&
      window.channelsConfig.chat.enableTranscript &&
      !isBotConnected
    ) {
      injectTranscriptButton(userId);

      setState({
        chatSessionId: getCookie(chatSessionCookieKey),
        transcriptsEnabled: window.channelsConfig.chat.enableTranscript,
      });
    }
    if (window.channelsConfig.popupMode) {
      showEndChatButton();
    }
  }

  const styleRichMedia = isRestored => {
    let messageGroupPolicyButtons;
    const messageGroups = document.querySelectorAll('.cx-message-group');
    if (!isRestored) {
      document.querySelectorAll('.cx-button').forEach(button => {
        if (button.innerText !== 'Survey') {
          button.disabled = true;
        }
      });
      // comes here when a new message has been added in the widget
      const lastMessageGroup = messageGroups[messageGroups.length - 2];
      if (lastMessageGroup) {
        lastMessageGroup.querySelectorAll('.cx-button').forEach(button => {
          if (
            hiddenButtons.length &&
            button.innerText.indexOf(hiddenButtons[hiddenButtons.length - 1]) !== -1
          ) {
            button.classList.add('option-selected');
            hiddenButtons = [];
          }
        });
      }
    } else {
      // comes here when widget is reloaded on page reload
      messageGroups.forEach((item, i) => {
        if (i !== messageGroups.length - 1) {
          item.querySelectorAll('.cx-button').forEach(button => {
            button.disabled = true;
          });
        }
        if (item.querySelectorAll('.cx-hidden-message').length && i !== 0) {
          let matched;
          messageGroupPolicyButtons.querySelectorAll('.cx-button').forEach(button => {
            if (button.innerText.indexOf(hiddenButtons[0]) !== -1 && !matched) {
              button.classList.add('option-selected');
              hiddenButtons.shift();
              matched = true;
            }
          });
        }
        messageGroupPolicyButtons = item;
      });
    }
  };

  CXBus.subscribe('WebChatService.ended', () => {
    if (isUserConnected) {
      const { chatSurveyLink } = getState();
      if (chatSurveyLink) {
        let channel;
        if (channelConnected.includes('Agent') && channelConnected.includes('Bot')) {
          channel = 'chatbotlivechat';
        } else if (channelConnected.includes('Agent')) {
          channel = 'livechat';
        } else {
          channel = 'chatbot';
        }
        channelConnected = [];
        window._genesys.widgets.bus.command('WebChat.injectMessage', {
          type: 'html',
          custom: true,
          text: `<div class = 'cx-message-group'>
                  <div class = 'cx-rich-media'>
                  <div class = 'cx-structure cx-generic cx-var-2'>
                  <h2 class = 'cx-markdown-survey' style-group='3'>
                  <p>Thank you for contacting Travelers. We were happy to assist you today. Please rate your experience by clicking the button below.</p>
                  </h2>
                  <a class='cx-button' href=${chatSurveyLink}&channel=${channel} target='_blank'>Survey</a>
                  </div>
                  </div>
                  </div>`,
        });
      }
      isUserConnected = false;
    }
  });

  CXBus.subscribe('WebChatService.botDisconnected', () => {
    isUserConnected = false;
  });

  CXBus.subscribe('WebChat.opened', () => {
    window.dispatchEvent(
      new CustomEvent('chat.opened', {
        detail: {
          subject: 'chat',
          subTopic: 'chatwindow',
        },
      }),
    );
    const { transcriptsEnabled } = getState();
    if (isChatRestoring && transcriptsEnabled && !isBotConnected) {
      injectTranscriptButton(userId);
    }
    isChatRestoring = false;
    styleRichMedia(true);
  });

  CXBus.subscribe('WebChat.started', () => {
    toggleUserTyping(false, 0);
  });

  CXBus.subscribe('WebChat.completed', () => {
    setState({
      transcriptsEnabled: null,
      transcriptRequestSucceeded: null,
    });
    isChatRestoring = false;
    window.dispatchEvent(
      new CustomEvent('chat.completed', {
        detail: {
          subject: 'chat',
          subTopic: 'chatend',
          value: getCookie(chatSessionCookieKey),
        },
      }),
    );
  });

  // No longer a requirement for this tag
  CXBus.subscribe('WebChat.cancelled', () => {
    window.dispatchEvent(
      new CustomEvent('chat.cancelled', {
        detail: {
          subject: 'chat',
          subTopic: 'chatcancel',
          value: getCookie(chatSessionCookieKey), // TODO: no session here?
        },
      }),
    );
  });

  CXBus.subscribe('WebChat.closed', () => {
    setState({ chatSessionId: null });
    window.dispatchEvent(
      new CustomEvent('chat.closed', {
        detail: {
          subject: 'chat',
          subTopic: 'chatclose',
        },
      }),
    );
  });

  CXBus.subscribe('WebChat.messageAdded', () => {
    // check the bowser tab is active and highlight if not
    if (!isWindowActive) {
      Notification.notifyTab();
    }
    styleRichMedia(false);
  });

  CXBus.subscribe('WebChatService.agentConnected', onConnected);
  CXBus.subscribe('WebChatService.started', webServiceStartedEvent => {
    const sessionId = webServiceStartedEvent.data.sessionID;
    window.dispatchEvent(
      new CustomEvent('chat.started', {
        detail: {
          subject: 'chat',
          subTopic: 'chatstart',
          value: sessionId,
        },
      }),
    );
    const { overrides, surveyLink } = window.channelsConfig.chat;
    setState({ chatSurveyLink: `${surveyLink}&session_id=${sessionId}` });
    if (overrides) {
      overrides.forEach(override => {
        override.paths.forEach(path => {
          if (window.location.href.includes(path)) {
            if (override.config.surveyLink) {
              // TODO: this should be set using setState, but not until a future major update, as it would break compatibility with older versions
              setState({ chatSurveyLink: `${override.config.surveyLink}&session_id=${sessionId}` });
            }
          }
        });
      });
    }
  });

  CXBus.subscribe('WebChatService.botConnected', onConnected);

  CXBus.subscribe('WebChatService.ended', () => {
    if (window.channelsConfig.popupMode) {
      const endChatButton = document.querySelector('.cx-end-chat-button');
      endChatButton.style.display = 'none';
    }
  });

  CXBus.subscribe('WebChatService.restored', () => {
    // Restrict text input until agent connected
    toggleUserTyping(false, 1000);
    isChatRestoring = true;
  });

  // added this listener to detect click on the deep link inside bot's reply
  CXBus.subscribe('RichMedia.created', event => {
    const deepLinks = event.data.instance && event.data.instance.getElementsByTagName('a');
    deepLinks.forEach(deepLink => {
      deepLink.addEventListener('click', () => {
        const widgetWidth = document.getElementsByClassName('cx-body')[0].clientWidth;
        const deviceWidth = window.screen.width;
        // minimize widget if it is in mobile mode
        if (widgetWidth === deviceWidth) {
          CXBus.command('WebChat.minimize');
        }
        window.dispatchEvent(
          new CustomEvent('RichMedia.click', {
            detail: { subject: 'SSE' },
          }),
        );
      });
    });
    // scroll window to the bottom when rich-media components are added
    const chatContainer = document.getElementsByClassName('cx-transcript')[0];
    if (chatContainer) {
      chatContainer.scrollTo(0, chatContainer.scrollHeight);
    }
  });

  // move textback message hidden into selected option to resolve masking issue
  CXBus.subscribe('WebChatService.ready', () => {
    CXBus.command('WebChatService.registerPreProcessor', {
      preprocessor(message) {
        if (message.from.nickname === 'Virtual Assistant' && message.type === 'Message') {
          toggleUserTyping(true, 1000);
        }
        // Check if the message is from customer and some additional checking.
        if (message.from.type === 'Client' && typeof message.eventAttributes !== 'undefined') {
          hiddenButtons.push(message.text);
          const response = message.text;
          if (response && response.indexOf('*') !== -1) {
            return message;
          }
          /* eslint-disable no-param-reassign */
          message.text = '';
        }
        return message;
      },
    });
  });

  CXBus.subscribe('RichMedia.click', event => {
    const actions = event.data && event.data.actions;
    let subject = '';
    if (actions.textback === 'CHAT_WITH_REPRESENTATIVE') {
      subject = 'LIVECHAT';
    } else if (actions.url && actions.url.indexOf('tel') !== -1) {
      subject = 'PHONE';
    }
    if (subject) {
      window.dispatchEvent(
        new CustomEvent('RichMedia.click', {
          detail: { subject },
        }),
      );
    }
  });
}

function registerCobrowseChannelEvents(cobrowseAPI) {
  if (cobrowseAPI) {
    let cobrowseId;
    let subject;

    // triggers when customer starts cobrowse and session id is generated
    cobrowseAPI.onSessionStarted.add(details => {
      const subTopic = 'cust_connect';
      cobrowseId = details.token;
      subject = details.widgetOrigin === 'WebChat' ? 'chat_cobrowse' : 'phone_cobrowse';
      window.dispatchEvent(
        new CustomEvent('cobrowse.started', {
          detail: {
            subject,
            subTopic,
            value: cobrowseId,
          },
        }),
      );
    });

    // triggers when ISR joins the cobrowse session
    cobrowseAPI.onAgentJoined.add((agent, session) => {
      cobrowseId = session.token;
      window.dispatchEvent(
        new CustomEvent('cobrowse.agentJoined', {
          detail: {
            subject,
            subTopic: 'isr_connect',
            value: cobrowseId,
          },
        }),
      );
    });

    // triggers when cobrowse session is disconnected
    cobrowseAPI.onSessionEnded.add(details => {
      const subTopic = details.reason === 'self' ? 'disconnect_cust' : 'disconnect';
      window.dispatchEvent(
        new CustomEvent('cobrowse.ended', {
          detail: {
            subject,
            subTopic,
            value: cobrowseId,
          },
        }),
      );
    });
  }
}

function dispatchChannelsListeners() {
  window.dispatchEvent(
    new CustomEvent('channels.events', {
      detail: [
        { eventName: 'chat.opened', tagType: 'meta' },
        { eventName: 'chat.started', tagType: 'meta' },
        { eventName: 'chat.completed', tagType: 'meta' },
        { eventName: 'chat.cancelled', tagType: 'meta' },
        { eventName: 'chat.closed', tagType: 'meta' },
        { eventName: 'chat.linkClicked', tagType: 'click' },
        { eventName: 'chat.linkInserted', tagType: 'meta' },
        { eventName: 'chat.eligibility', tagType: 'meta' },
        { eventName: 'cobrowse.started', tagType: 'meta' },
        { eventName: 'cobrowse.linkClicked', tagType: 'click' },
        { eventName: 'cobrowse.agentJoined', tagType: 'meta' },
        { eventName: 'cobrowse.ended', tagType: 'meta' },
        { eventName: 'RichMedia.click', tagType: 'click' },
      ],
    }),
  );
}

export { registerCobrowseChannelEvents, registerChatChannelEvents, dispatchChannelsListeners };
