import caretBackgroundImage from './caret';

function filter(items, value) {
  items.forEach(item => {
    if (value) {
      item.hidden = !item.textContent.toLowerCase().includes(value.toLowerCase());
    } else {
      item.hidden = false;
    }
  });
}

function adjustHeight(input, list) {
  const { top: inputTop, height: inputHeight } = input.getBoundingClientRect();
  const { height: listHeight } = list.getBoundingClientRect();
  const distanceToBottom = window.innerHeight - inputTop - inputHeight;
  if (distanceToBottom < 112) {
    if (input.previousSibling !== list) {
      input.insertAdjacentElement('beforebegin', list);
    }
    list.style.maxHeight = `${inputTop}px`;
    list.style.top = `-${listHeight < inputTop ? listHeight : inputTop}px`;
  } else {
    if (input.nextSibling !== list) {
      input.insertAdjacentElement('afterend', list);
    }
    list.style.removeProperty('top');
    list.style.maxHeight = `${distanceToBottom}px`;
  }
}

function open(input, list) {
  if (list.hidden) {
    const formElement = document.querySelector('.cx-form.cx-form-horizontal');
    const formWrapperElement = document.querySelector('.cx-form-wrapper');

    if (formElement) formElement.style.overflow = 'visible';
    if (formWrapperElement) formWrapperElement.style.overflow = 'visible';

    list.hidden = false;
    adjustHeight(input, list);
  }
}

function close(list) {
  if (!list.hidden) {
    const formElement = document.querySelector('.cx-form.cx-form-horizontal');
    const formWrapperElement = document.querySelector('.cx-form-wrapper');
    if (formElement) formElement.style.removeProperty('overflow');
    if (formWrapperElement) formWrapperElement.style.removeProperty('overflow');
    list.hidden = true;
  }
}

function setupSearchableDropdown({ id, options }) {
  const input = document.getElementById(id);
  const inputForValue = document.getElementById(id.slice(0, id.length - 5));
  if (input.value) {
    const text = options.find(({ value }) => value === input.value).text || '';
    const value = text ? input.value : '';
    input.value = text;
    inputForValue.value = value;
  }
  input.classList.add('select--caret');
  input.parentElement.classList.add('searchableSelect__data');
  input.style.backgroundImage = caretBackgroundImage();
  const list = document.createElement('ul');
  list.id = `${id}_list`;
  list.hidden = true;
  list.classList.add('searchableSelect__list');
  const items = options.map(({ text, value }) => {
    const item = document.createElement('li');
    item.classList.add('searchableSelect__option');
    item.textContent = text;
    item.dataset.value = value;
    item.tabIndex = -1;
    return item;
  });
  items.forEach(item => list.appendChild(item));
  input.insertAdjacentElement('afterend', list);
  input.autocomplete = 'off';

  /*
  This horrible event listener is to prevent the list from closing when it's scrollbar is clicked
  due to focus shifting away from the input on mousedown for no good reason. For some reason,
  browsers will remove focus from an element with a scrollbar on mousedown of said scrollbar.
  preventDefault is enough to stop this on most browsers, but IE is the worst so it needs extra
  logic. preventDefault will not work, but IE fires a focusin event when focus is moved away from
  the input. So, on mousedown, we listen for the input to lose focus, and re-open the list after
  it does. The focusin event listener is removed afterwards so that it only runs on this specific
  occasion.
  */
  list.addEventListener('mousedown', e => {
    e.preventDefault();
    function reopen({ relatedTarget }) {
      if (relatedTarget === input && list.hidden) {
        open(input, list);
        list.focus();
      }
      document.removeEventListener('focusin', reopen);
    }
    document.addEventListener('focusin', reopen);
  });

  ['click', 'focusin'].forEach(event => {
    document.addEventListener(event, ({ target }) => {
      if (target === input && list.hidden) {
        open(input, list);
      }
      if (target !== input && !list.contains(target)) {
        close(list);
      }
    });
  });

  window.addEventListener('resize', () => {
    if (!list.hidden) {
      adjustHeight(input, list);
    }
  });

  document.addEventListener('keydown', e => {
    const { target, key } = e;
    const pieces = [input, ...items];
    if (target === input || list.contains(target)) {
      if (/ArrowDown|Down/.test(key)) {
        e.preventDefault();
        const currentIndex = pieces.indexOf(target);
        const next = pieces.find(({ hidden }, index) => !hidden && index > currentIndex);
        if (next) {
          next.focus();
        }
      }
      if (/ArrowUp|Up/.test(key)) {
        e.preventDefault();
        const reversed = pieces.slice().reverse();
        const currentIndex = reversed.indexOf(target);
        const previous = reversed.find(({ hidden }, index) => !hidden && index > currentIndex);
        if (previous) {
          previous.focus();
        }
      }
    }
  });
  input.addEventListener('input', ({ currentTarget: { value } }) => {
    const matchedOption = options.find(option => option.text.toLowerCase() === value.toLowerCase());
    if (matchedOption) {
      inputForValue.value = matchedOption.value;
    }
    if (!value) {
      inputForValue.value = '';
    }
    if (list.hidden) {
      open(input, list);
    }
    filter(items, value);
    adjustHeight(input, list);
  });
  items.forEach(item =>
    ['click', 'keydown'].forEach(event =>
      item.addEventListener(event, ({ key }) => {
        if (!key || key === 'Enter') {
          input.value = item.textContent;
          inputForValue.value = item.dataset.value;
          filter(items, item.textContent);
          close(list);
          const changeEvent = document.createEvent('Event');
          changeEvent.initEvent('change', true, true);
          input.dispatchEvent(changeEvent);
        }
      }),
    ),
  );
}

export default setupSearchableDropdown;
