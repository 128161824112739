import { exclamationCircle1_18 as exclamationCircle } from '@trv-ebus/tds-icons';
import Logger from './logger/logger';
import Localizations from './localizations';
import { clearStorage, getState, setState, getCookie } from './storageManager';
import { loadScript, loadStyleSheet } from './utilities';
import setupSearchableDropdown from './searchableSelect';
import {
  registerCobrowseChannelEvents,
  registerChatChannelEvents,
  dispatchChannelsListeners,
} from './eventRegister';
import track from './altocloud';
import caretBackgroundImage from './caret';

let cobrowseAPI;
const searchableSelects = [];
const hiddenSelectText = [];

function validateInput(domInput, inputConfig) {
  if (domInput) {
    let validationMessage;
    const nextRowChildren = domInput[0]?.closest('tr')?.nextSibling?.children;
    if (nextRowChildren) {
      validationMessage = [...nextRowChildren].find(child =>
        child.classList.contains('input__error-data'),
      );
    }
    let isValid = false;
    if (!inputConfig.searchable) {
      isValid = !!domInput[0].value.trim();
    } else {
      const searchableSelectList = document.getElementById(`${domInput[0].id}_list`);
      isValid = Array.from(searchableSelectList.children).some(
        child =>
          child.textContent.toLowerCase() === domInput[0].value.toLowerCase() ||
          (!inputConfig.required && !domInput[0].value.trim()),
      );
    }
    if (isValid) {
      if (validationMessage) {
        const errorRowToBeRemoved = validationMessage.parentNode;
        errorRowToBeRemoved.parentNode.removeChild(errorRowToBeRemoved);
      }
      return true;
    }
    if (!validationMessage) {
      const errorRow = document.createElement('tr');
      const errorHeader = document.createElement('th');
      const errorData = document.createElement('td');
      errorData.classList.add('input__error-data');
      errorRow.appendChild(errorHeader);
      errorRow.appendChild(errorData);

      const inputRow = domInput[0].closest('tr');

      const labelValue = document
        .querySelector(`[for=${inputConfig.id}]`)
        .textContent.toLowerCase();
      const vowels = '^[aieou].*';

      const article = labelValue[0].match(vowels) ? 'an' : 'a';
      let inputAction = `enter ${article}`;
      if (inputConfig.type === 'select') {
        inputAction = `select ${article}`;
      }
      if (inputConfig.searchable) {
        inputAction = 'select a valid';
      }
      errorData.innerHTML = `${exclamationCircle.svg()}Please ${inputAction} ${labelValue}`;
      inputRow.insertAdjacentElement('afterend', errorRow);
    }
    return false;
  }
  return false;
}

function openChat() {
  Logger.info('chat.js', 'openChat()', 'Opening chat...');
  const { userDataExtensions, form, overrides, userData } = window.channelsConfig.chat;

  const openConfig = {
    form: {
      autoSubmit: form.autoSubmit,
    },
  };
  if (userData) openConfig.userData = userData;
  if (overrides) {
    overrides.forEach(override => {
      override.paths.forEach(path => {
        if (window.location.href.includes(path)) {
          openConfig.userData = override.config.userData;
          // TODO: remove the right side of the conditional once myT fixes the "enableTranscipt" typo.
          window.channelsConfig.chat.enableTranscript =
            override.config.enableTranscript || override.config.enableTranscipt;
        }
      });
    });
  }

  if (form) {
    form.inputs.forEach(input => {
      if (input.value) {
        if (typeof input.value === 'string') {
          openConfig.form[input.name] = input.value;
        } else if (typeof input.value === 'function') {
          openConfig.form[input.name] = input.value();
        }
      }
    });
  }
  if (userDataExtensions) {
    userDataExtensions.forEach(extension => {
      if (window.location.href === extension.path) {
        openConfig.userData = { ...(openConfig.userData || {}), ...extension.data };
      }
    });
  }
  window._genesys.widgets.bus
    .command('WebChat.open', openConfig)
    .done(() => {
      // WebChat opened successfully
      const initialForm = document.getElementsByClassName('cx-form-wrapper')[0];
      if (initialForm && form.autoSubmit) initialForm.style.display = 'none';
      Logger.info('chat.js', 'openChat()', 'Chat opened successfully.');
    })
    .fail(error => {
      // WebChat isn't open or no active chat session
      Logger.error('chat.js', 'openChat()', `Failed to open chat: ${error}`);
    });
}

function openCobrowse() {
  const isCobrowseActive = document.getElementsByClassName('gcb-toolbar').length;
  if (!isCobrowseActive) {
    window.dispatchEvent(
      new CustomEvent('cobrowse.linkClicked', {
        detail: {
          subject: 'phone_cobrowse',
        },
      }),
    );
    setTimeout(() => {
      const CXBus = window._genesys.widgets.bus;
      CXBus.command('CoBrowse.start')
        .done(() => {
          Logger.info('channels.js', 'registerClickStartCobrowseEvent()', 'voice cobrowse started');
        })
        .fail(() => {
          Logger.error(
            'channels.js',
            'registerClickStartCobrowseEvent()',
            'failed to start voice cobrowse',
          );
        });
    }, 150);
  }
}

function validateChatConfig() {
  let valid = true;
  const { chat: chatConfig } = window.channelsConfig;
  if (!chatConfig) {
    Logger.error('channels.js', 'validateConfigs()', 'Chat config is not defined!');
    valid = false;
  } else if (!chatConfig.userData) {
    Logger.error('channels.js', 'validateConfigs()', 'Chat config is missing userData property!');
    valid = false;
  }

  return valid;
}

function validateCobrowseConfig() {
  let valid = true;
  const { cobrowse: cobrowseConfig } = window.channelsConfig;
  if (!cobrowseConfig) {
    Logger.error('channels.js', 'validateConfigs()', 'Cobrowse config is not defined!');
    valid = false;
  } else {
    if (!cobrowseConfig.src) {
      Logger.error('channels.js', 'validateConfigs()', 'Cobrowse config is missing src property!');
      valid = false;
    }
    if (!cobrowseConfig.url) {
      Logger.error('channels.js', 'validateConfigs()', 'Cobrowse config is missing url property!');
      valid = false;
    }
    if (!cobrowseConfig.triggerClass) {
      Logger.error(
        'channels.js',
        'validateConfigs()',
        'Cobrowse config is missing triggerClass property!',
      );
      valid = false;
    }
  }
  return valid;
}

function setConfigs(channels) {
  const { chat: chatConfig, cobrowse: cobrowseConfig } = window.channelsConfig;
  if (!window._genesys)
    window._genesys = {
      widgets: {
        main: {
          debug: Logger.isDebugEnabled(),
          theme: 'light',
          lang: 'en',
          downloadGoogleFont: false,
          plugins: [],
          i18n: Localizations,
          cookieOptions: {
            domain: window.location.hostname,
            path: '/',
          },
        },
        onReady: () => {
          registerChatChannelEvents(chatConfig?.userData?.userId ?? '');
          window.CXBus.subscribe('WebChat.chatButtonDisplayed', () => {
            const chatButton = document.querySelector('.cx-widget.cx-side-button-group');
            if (chatButton) {
              const clone = chatButton.cloneNode(true);
              const parent = chatButton.parentElement;
              parent.removeChild(chatButton);
              parent.appendChild(clone);
              clone.addEventListener('click', openChat);
            }
          });
        },
      },
      onReady: [
        APIs => {
          if (APIs.cobrowse) {
            registerCobrowseChannelEvents(APIs.cobrowse);
          }
        },
      ],
    };
  if (window.channelsConfig.popupMode) {
    window._genesys.widgets.main.mobileMode = true;
    window.addEventListener('chat.opened', () => {
      document.body.style.overflowY = 'hidden';
      const windowControlButtons = document.querySelector(
        '.cx-button-group.cx-buttons-window-control',
      );
      if (windowControlButtons) windowControlButtons.hidden = true;
      const cancelButton = document.querySelector('[data-message="ChatFormCancel"]');
      if (cancelButton) cancelButton.style.display = 'none';
      function warnBeforeLeaving(event) {
        event.preventDefault();
        event.returnValue = '';
      }
      function endChat() {
        const keysCookie = getCookie('_genesys.widgets.webchat.state.keys');
        const sessionId = getCookie('_genesys.widgets.webchat.state.session');
        if (keysCookie && sessionId) {
          let keys;
          try {
            keys = JSON.parse(decodeURIComponent(keysCookie));
          } catch (err) {
            Logger.error(`Unable to parse Genesys keys: ${err.message}`);
            return;
          }
          const data = Object.entries(keys).reduce(
            (acc, [key, value], i, arr) =>
              `${acc}${key}=${value}${i === arr.length - 1 ? '' : '&'}`,
            '',
          );
          const disconnectUrl = `${'https://cct-gms-prod.travelers.com/genesys/2/chat/OmniChat'}/${sessionId}/disconnect`;
          const beaconWorked = window?.navigator?.sendBeacon?.(
            disconnectUrl,
            new Blob([data], { type: 'application/x-www-form-urlencoded' }),
          );
          if (!beaconWorked) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', disconnectUrl, false);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.send(data);
          }
        }
      }
      window.addEventListener('beforeunload', warnBeforeLeaving);
      window.addEventListener('unload', endChat);
      const endChatSessionButton = document.querySelector(
        '.cx-end-confirm.cx-btn.cx-btn-primary.i18n',
      );
      endChatSessionButton.addEventListener('click', () => {
        window.removeEventListener('beforeunload', warnBeforeLeaving);
        window.removeEventListener('unload', endChat);
        const chatSurveyLink = getState()?.chatSurveyLink;
        if (chatSurveyLink) {
          window.open(chatSurveyLink, '_self');
        }
      });
      window.CXBus.subscribe('WebChatService.ended', () => {
        window.removeEventListener('beforeunload', warnBeforeLeaving);
        window.removeEventListener('unload', endChat);
      });
    });
  }
  if (!window._gt) window._gt = [];
  if (channels.includes('chat') && validateChatConfig()) {
    // TODO: remove this once myT fixes the "enableTranscipt" typo on their end.
    window.channelsConfig.chat.enableTranscript =
      chatConfig.enableTranscript || chatConfig.enableTranscipt;
    if (chatConfig.localization) {
      window._genesys.widgets.main.i18n.en.webchat = {
        ...Localizations.en.webchat,
        ...chatConfig.localization,
      };
    }
    window._genesys.widgets.webchat = {
      restoreTimeout: 300000,
      apikey: '', // Used for Apigee service only
      dataURL: window.channelsConfig.chat.gmsUrl || 'https://cct-gms-prod.travelers.com/genesys/2/chat/OmniChat',
      userData: chatConfig.userData,
      actionsMenu: false,
      ajaxTimeout: 15000,
      autoinvite: {
        enabled: false,
      },
      chatButton: {
        enabled: false,
        openDelay: 0,
        effectDuration: 0,
        hideDuringInvite: true,
      },
      cometD: {
        enabled: false,
        cometURL: window.channelsConfig.chat.cometdUrl || 'https://cct-gms.dev.travelers.com/genesys/cometd',
        channel: window.channelsConfig.chat.cometdChannel || '/service/chatV2/OmniChat',
        apiURL: window.channelsConfig.chat.cometdApiUrl || 'https://cct-gms.dev.travelers.com/genesys/2/chat-ntf',
        websocketEnabled: false,
      },
    };
    if (chatConfig.form) {
      window._genesys.widgets.webchat.form = {
        ...chatConfig.form,
        inputs: chatConfig.form.inputs.reduce((acc, formInput) => {
          const genesysInput = formInput;
          if (formInput.required) {
            genesysInput.validate = (event, form, input) => validateInput(input, formInput);
          }
          if (formInput.type === 'select' && formInput.submitText) {
            const selectText = {
              type: 'hidden',
              name: formInput.submitText.name || `${genesysInput.name}_text`,
              id: `${genesysInput.id}_text`,
            };
            acc.push(selectText);
            hiddenSelectText.push(selectText);
          }
          if (formInput.type === 'select' && formInput.searchable) {
            genesysInput.type = 'text';
            genesysInput.validate = (event, form, input) => validateInput(input, formInput);
            acc.push({
              type: 'hidden',
              name: genesysInput.name,
              id: genesysInput.id,
            });
            genesysInput.name = `${genesysInput.name}_text`;
            genesysInput.id = `${genesysInput.id}_text`;
            searchableSelects.push(genesysInput);
          }
          acc.push(genesysInput);
          return acc;
        }, []),
      };
      window.addEventListener('chat.opened', () => {
        const formElement = document.querySelector('.cx-form-wrapper');
        if (formElement.style.display === 'block') {
          chatConfig.form.inputs.forEach(input => {
            if (input.characterCounter && input.maxlength) {
              if (!document.getElementById(`${input.id}_characterCounter`)) {
                const inputElement = document.getElementById(input.id);
                const characterCounterRow = document.createElement('tr');
                const emptyHeader = document.createElement('th');
                characterCounterRow.appendChild(emptyHeader);
                const characterCounter = document.createElement('td');
                characterCounter.className = 'characterCounter';
                characterCounter.id = `${input.id}_characterCounter`;
                characterCounter.innerText = `${input.maxlength - inputElement.value.length}/${
                  input.maxlength
                }`;
                characterCounterRow.appendChild(characterCounter);
                inputElement.closest('tr').insertAdjacentElement('afterend', characterCounterRow);
                inputElement.addEventListener('input', () => {
                  characterCounter.innerText = `${input.maxlength - inputElement.value.length}/${
                    input.maxlength
                  }`;
                });
              }
            }
          });
          if (chatConfig.form.inputs.length === 0) {
            const chatButtonContainer = document.querySelector('.cx-form > .cx-button-group');
            chatButtonContainer.style.display = 'none';
          } else {
            // set focus to first input element in chat form:
            const firstName = document.getElementsByClassName('cx-input')[0];
            if (firstName) firstName.focus();
            searchableSelects.forEach(dropdown => setupSearchableDropdown(dropdown));
            hiddenSelectText.forEach(input => {
              const selectPair = document.getElementById(input.id.slice(0, input.id.length - 5));
              const textInput = document.getElementById(input.id);
              if (selectPair && textInput) {
                textInput.value = selectPair.options[selectPair.selectedIndex].textContent;
                selectPair.addEventListener('change', () => {
                  textInput.value = selectPair.options[selectPair.selectedIndex].textContent;
                });
              }
            });
            document.querySelectorAll('select.cx-input.cx-form-control').forEach(select => {
              select.classList.add('select--caret');
              select.style.backgroundImage = caretBackgroundImage();
            });
          }
        }
      });
    }

    if (chatConfig.enableAltocloud) {
      track();
    }
  }

  if (channels.includes('cobrowse') && validateCobrowseConfig()) {
    if (cobrowseConfig.localization) {
      window._genesys.widgets.main.i18n.en.cobrowse = {
        ...Localizations.en.cobrowse,
        ...cobrowseConfig.localization,
      };
    }
    if (channels.includes('chat') && window._genesys.widgets.webchat) {
      window._genesys.widgets.webchat.actionsMenu = true;
    }
    window._genesys.widgets.cobrowse = {
      css: {
        server: false,
        browser: true,
      },
      disableWebSockets: false,
      src: cobrowseConfig.src,
      url: cobrowseConfig.url,
    };
  }
  const { genesysConfig } = getState();
  if (!window._genesys.widgets.webchat && genesysConfig && genesysConfig.webchat) {
    window._genesys.widgets.webchat = genesysConfig.webchat;
  }
  if (!window._genesys.widgets.cobrowse && genesysConfig && genesysConfig.cobrowse) {
    window._genesys.widgets.cobrowse = genesysConfig.cobrowse;
  }
  setState({ genesysConfig: window._genesys.widgets });
}

function loadGenesysWidgets() {
  return new Promise(resolve => {
    loadStyleSheet(`${'https://apps.mypurecloud.com/widgets/9.0.017.07'}/widgets.min.css`);
    loadScript(`${'https://apps.mypurecloud.com/widgets/9.0.017.07'}/cxbus.min.js`, () => {
      window.CXBus.configure({
        debug: Logger.isDebugEnabled(),
        pluginsPath: `${'https://apps.mypurecloud.com/widgets/9.0.017.07'}/plugins/`,
      });
      window.CXBus.loadPlugin('widgets-core');
      resolve();
    });
    Logger.info('chat.js', 'loadChat', 'Chat loading done.');
  });
}

function injectChatButton() {
  const buttonWrapper = document.createElement('div');
  buttonWrapper.setAttribute('id', 'chatStartButtonWrapper');
  buttonWrapper.setAttribute('class', 'cx-side-button-group chat-button__wrapper');
  const btn = document.createElement('button');
  btn.innerHTML = 'Chat';
  btn.setAttribute('id', 'chatStartButton');
  btn.setAttribute('class', 'cx-icon cx-side-button chat-button');
  buttonWrapper.appendChild(btn);
  btn.addEventListener('click', openChat);
  buttonWrapper.style.display = 'none';
  document.body.appendChild(buttonWrapper);
}

function setupCobrowseListener() {
  window.document.addEventListener('click', ({ target: { classList } }) => {
    const { triggerClass } = window.channelsConfig.cobrowse;
    if (triggerClass && classList.contains(triggerClass)) {
      openCobrowse();
    }
  });
}

async function init(channels) {
  if (window._genesys == null && channels && channels.length > 0) {
    const sessionId = getCookie('_genesys.widgets.webchat.state.session');
    Logger.info('channels.js', 'init()', 'Initializing channels...');
    dispatchChannelsListeners();
    setConfigs(channels);
    await loadGenesysWidgets();
    if (window.channelsConfig.popupMode) {
      openChat();
    } else {
      if (channels.includes('chat')) {
        injectChatButton();
        if (window.channelsConfig.chat.autoOpen && !sessionId) {
          openChat();
        }
      }
      if (channels.includes('cobrowse')) {
        setupCobrowseListener();
      }
    }
  }
}

function clearAllWebChatCookies() {
  clearStorage();
  // exit any active cobrowse session
  if (cobrowseAPI && document.getElementsByClassName('gcb-toolbar').length) {
    cobrowseAPI.exitSession();
  }
}

function updateUserData(data) {
  window._genesys.widgets.bus.command('WebChatService.updateUserData', data);
}

function extendUserData(data) {
  if (!window.channelsConfig || !window.channelsConfig.chat) {
    Logger.error('channels.js', 'extendUserData()', 'Chat config is not defined!');
    return;
  }
  if (!window.channelsConfig.chat.userDataExtensions) {
    window.channelsConfig.chat.userDataExtensions = [];
  }
  const newExtension = {
    path: window.location.href,
    data,
  };
  window.channelsConfig.chat.userDataExtensions = window.channelsConfig.chat.userDataExtensions.filter(
    extension => extension.path !== newExtension.path,
  );
  window.channelsConfig.chat.userDataExtensions.push(newExtension);
}

export { init, clearAllWebChatCookies, extendUserData, updateUserData };
