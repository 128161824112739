let msgCount = 0;
const oldTitle = document.title;
let displayMsg = '';
let timeoutId;

function blink() {
  displayMsg = `(${msgCount}) New message${msgCount > 1 ? 's' : ''} recieved!`;
  document.title = document.title === displayMsg ? ' ' : displayMsg;
}

function clear() {
  clearInterval(timeoutId);
  document.title = oldTitle;
  window.onmousemove = null;
  timeoutId = null;
  msgCount = 0;
}

function notifyTab() {
  msgCount += 1;
  if (!timeoutId) {
    timeoutId = setInterval(blink, 1000);
    window.onmousemove = clear;
  }
}

export default { notifyTab };
