import Logger from './logger/logger';
import { getCookie, chatSessionCookieKey, setState, getState } from './storageManager';

function injectMessage(text) {
  window._genesys.widgets.bus
    .command('WebChat.injectMessage', {
      type: 'text',
      text,
      custom: false,
    })
    .fail(error => {
      Logger.error(
        'transcript.js',
        'injectConfirmationMessageToChatTranscript()',
        `Failed to inject custom message: ${error}`,
      );
    });
}

function disableTranscriptButton(button, requestSucceeded) {
  const transcriptButton = button;
  transcriptButton.disabled = true;
  transcriptButton.value = requestSucceeded
    ? 'Chat transcript requested'
    : 'Chat transcript request unsuccessful';
}

async function requestTranscript(userId) {
  const transcriptButton = document.getElementById('requestTranscript');
  const { chatSessionId } = getState();
  const sessionId = getCookie(chatSessionCookieKey) || chatSessionId;
  if (transcriptButton && sessionId) {
    try {
      const response = await fetch(`${'n/a'}/api/transcripts`, {
        method: 'POST',
        body: JSON.stringify({ sessionId, userId }),
        headers: { 'content-type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      disableTranscriptButton(transcriptButton, true);
      injectMessage(
        'A link to your chat transcript will be emailed to you at the end of the session.',
      );
      if (getCookie(chatSessionCookieKey)) {
        setState({ transcriptRequestSucceeded: true });
      }
    } catch {
      disableTranscriptButton(transcriptButton, false);
      injectMessage(
        'Due to a system error, your request for the chat transcript was unsuccessful. Please copy and paste the chat session for your reference.',
      );
      if (getCookie(chatSessionCookieKey)) {
        setState({ transcriptRequestSucceeded: false });
      }
    }
  }
}

function injectTranscriptButton(userId) {
  const listItem = document.createElement('li');
  listItem.className = 'cx-transcript-button-container';
  const transcriptButton = document.createElement('input');
  transcriptButton.type = 'button';
  transcriptButton.id = 'requestTranscript';
  transcriptButton.className = 'ct-menu-transcript-button';
  transcriptButton.value = 'Request chat transcript';
  const { transcriptRequestSucceeded } = getState();
  if (typeof transcriptRequestSucceeded === 'boolean') {
    disableTranscriptButton(transcriptButton, transcriptRequestSucceeded);
  } else {
    transcriptButton.onclick = () => requestTranscript(userId);
  }

  listItem.appendChild(transcriptButton);
  const footerMenu = document.querySelector('.cx-menu');
  if (footerMenu) {
    footerMenu.insertBefore(listItem, footerMenu.firstChild);
  }
}

export default injectTranscriptButton;
