function loadScript(scriptPath, onLoadFunc) {
  const scriptElement = document.createElement('script');
  scriptElement.src = scriptPath;
  if (onLoadFunc) scriptElement.onload = onLoadFunc;
  document.head.appendChild(scriptElement);
}

function loadStyleSheet(stylePath, charSet) {
  const linkElement = document.createElement('link');
  linkElement.type = 'text/css';
  linkElement.rel = 'stylesheet';
  linkElement.href = stylePath;
  if (charSet) linkElement.charset = charSet;
  document.head.appendChild(linkElement);
}

function extend(...args) {
  const extendedObj = args[0];
  args.forEach((arg, i) => {
    if (i === 0) {
      return;
    }
    Object.keys(arg).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(arg, key)) {
        extendedObj[key] = arg[key];
      }
    });
  });
  return extendedObj;
}
export { extend, loadScript, loadStyleSheet };
